import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { tokenStorage } from "../utils/tokenStorage";

function Logout() {
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);

  useEffect(() => {
    setAuth(false);
    tokenStorage.removeToken();
    navigate("/");
  }, [setAuth, navigate]);

  return <></>;
}

export default Logout;
