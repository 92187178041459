import { Box, Button } from "@mui/material";
import { isModerator } from "../../utils/isModerator";
import { PollsListItem, PollsListItemProps } from "./PollsListItem";
import { NewPoll, PollsAddForm } from "./PollsAddForm";
import { useContext, useState } from "react";
import { Poll, PollOption } from "../../pages/Spot";
import { AuthContext } from "../../context/AuthContext";
import { TarrifLevel } from "../../models/TarrifLevel";
import { LimitErrorModal } from "../modals/LimitErrorModal";
import { voteStorage } from "../../utils/voteStorage";
import { serverApi } from "../../api/ServerApi";
import { EditPoll } from "./PollsEditForm";

export type FullPoll = {
  poll: Poll;
  options: PollOption[];
};

interface PollsListProps {
  moderatorId: string;
  polls: Poll[];
  pollsOptions: PollOption[];
  connection: WebSocket;
  spotId: string;
}

export function PollsList(props: PollsListProps) {
  const { tarrif } = useContext(AuthContext);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [pollsLimit, setPollsLimit] = useState<boolean>(false);

  const fullPolls: FullPoll[] = props.polls.map((poll) => {
    const fullPoll: FullPoll = {
      poll: poll,
      options: props.pollsOptions.filter(
        (option) => option.poll_id === poll.id
      ),
    };
    return fullPoll;
  });

  function addNewPoll(poll: NewPoll) {
    const request = {
      id: props.spotId,
      event: "createPoll",
      name: poll.name,
      options: poll.options.join("|"),
      published: false,
    };

    if (props.connection) {
      props.connection.send(JSON.stringify(request));
    }
  }

  function deletePoll(pollId: number) {
    if (props.connection) {
      props.connection.send(
        JSON.stringify({ id: pollId, event: "deletePoll" })
      );
    }
  }

  function vote(pollId: number, optionId: number, votes: number) {
    serverApi
      .changeVotes(optionId, votes)
      .then(() => {
        props.connection.send(
          JSON.stringify({
            event: "vote",
            id: optionId,
            votes: votes,
          })
        );
      })
      .catch((err) => console.log(err))
      .finally(() => {
        voteStorage.setVote(pollId, optionId);
      });
  }

  function revote(pollId: number, optionId: number, votes: number) {
    serverApi
      .changeVotes(optionId, votes)
      .then(() => {
        props.connection.send(
          JSON.stringify({
            event: "vote",
            id: optionId,
            votes: votes,
          })
        );
      })
      .catch((err) => console.log(err))
      .finally(() => {
        voteStorage.removeVote(pollId);
      });
  }

  function publish(pollId: number, newStatus: boolean) {
    const request = {
      id: pollId,
      status: Number(newStatus),
      event: "publish",
    };
    if (props.connection) {
      props.connection.send(JSON.stringify(request));
    }
  }

  function updatedPoll(poll: EditPoll) {
    const request = {
      pollId: poll.pollId,
      pollName: poll.pollName,
      options: poll.options,
      spotId: props.spotId,
      event: "updatePoll",
    };
    if (props.connection) {
      props.connection.send(JSON.stringify(request));
    }
  }

  return (
    <Box sx={{ mt: 2 }}>
      <LimitErrorModal open={pollsLimit} onHide={() => setPollsLimit(false)} />
      {isModerator(props.moderatorId) && (
        <Button
          variant="contained"
          color="success"
          sx={{ mt: 2, width: "100%", boxShadow: "none", borderRadius: "0" }}
          onClick={() => {
            if (props.polls.length === 1 && tarrif === TarrifLevel.Base) {
              setPollsLimit(true);
            } else {
              setShowForm(!showForm);
            }
          }}
        >
          Добавить голосование
        </Button>
      )}
      {showForm && (
        <PollsAddForm
          handleSubmit={() => setShowForm(false)}
          setNewPoll={addNewPoll}
        />
      )}
      {fullPolls.length > 0 ? (
        <>
          {fullPolls.map((fullPoll: FullPoll) => {
            const itepProps: PollsListItemProps = {
              moderatorId: props.moderatorId,
              fullPoll: fullPoll,
              deletePoll: deletePoll,
              handleVote: vote,
              handleRevote: revote,
              handlePublish: publish,
              handleUpdatedPoll: updatedPoll,
            };
            if (isModerator(props.moderatorId)) {
              return <PollsListItem key={fullPoll.poll.id} {...itepProps} />;
            }
            if (!isModerator(props.moderatorId) && fullPoll.poll.published) {
              return <PollsListItem key={fullPoll.poll.id} {...itepProps} />;
            }
            return "";
          })}
          {!isModerator(props.moderatorId) &&
            fullPolls.filter(
              (fullPoll: FullPoll) => fullPoll.poll.published !== 0
            ).length === 0 && (
              <Box sx={{ mt: 2 }}>Нет доступных голосований</Box>
            )}
        </>
      ) : (
        <Box sx={{ mt: 2 }}>Еще нет голосований</Box>
      )}
    </Box>
  );
}
