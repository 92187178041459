import { Grid, Box, Button, Card, CardContent, TextField } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { Poll, PollOption } from "../../pages/Spot";

export interface PollsEditFormProps {
  poll: Poll;
  options: PollOption[];
  handleUpdatedPoll: (poll: EditPoll) => void;
  hideEdit: () => void;
}

export interface EditPoll {
  pollId: string | number;
  pollName: string;
  options: string[];
}

const PollFieldStyle = {
  width: "100%",
  mt: 2,
  "& .MuiInputBase-root": {
    borderRadius: "0",
    border: "1px solid #D2CED2",
    color: "#515E6F",
  },
  "& .MuiInputBase-root.Mui-focused": {
    borderRadius: "0",
    border: "1px solid #D2CED2",
  },
  "& .MuiFormLabel-root": {
    color: "#515E6F",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "#515E6F",
    background: "#F5F4F5",
  },
};

export function PollsEditForm(props: PollsEditFormProps) {
  const [optionFields, setOptionFields] = useState<ReactElement[]>([]);

  useEffect(() => {
    const propsOptions: ReactElement[] = [];
    props.options.forEach((option, index) => {
      const optionItem = (
        <TextField
          data-poll-option
          sx={{ width: "100%", mt: 2 }}
          defaultValue={option.option_name}
          size="small"
          key={index}
        />
      );
      propsOptions.push(optionItem);
    });
    setOptionFields(propsOptions);
  }, []);

  const addOptionField = () => {
    const field = (
      <TextField
        data-poll-option
        sx={PollFieldStyle}
        size="small"
        key={optionFields.length + 1}
      />
    );
    setOptionFields([...optionFields, field]);
  };

  const handleSubmit = () => {
    const updatedPoll: EditPoll = {
      pollId: props.poll.id,
      pollName: "",
      options: [],
    };
    const name = document
      .querySelector("[data-edit-poll-name]")
      ?.querySelector("input");
    const optionsInputs = document.querySelectorAll("[data-poll-option]");

    if (name && optionsInputs) {
      updatedPoll.pollName = String(name.value);
      optionsInputs.forEach((option) => {
        const input = option.querySelector("input");
        const value = (input as HTMLInputElement).value;
        if (value) {
          updatedPoll.options.push(value);
        }
      });
    }
    props.handleUpdatedPoll(updatedPoll);
    props.hideEdit();
  };

  return (
    <Card
      sx={{
        minWidth: 275,
        position: "relative",
        borderRadius: "0",
        boxShadow: "none",
        my: 2,
        "& .MuiInputBase-root": {
          borderRadius: "0",
          border: "0px solid #D2CED2",
          color: "#515E6F",
        },
        "& .MuiInputBase-root.Mui-focused": {
          borderRadius: "0",
          border: "0px solid #D2CED2",
        },
        "& .MuiFormLabel-root": {
          color: "#515E6F",
        },
        "& .MuiFormLabel-root.Mui-focused": {
          color: "#515E6F",
          background: "#F5F4F5",
        },
      }}
    >
      <CardContent>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="space-between"
          sx={{ p: 1 }}
        >
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
            <TextField
              sx={{ width: "100%" }}
              data-edit-poll-name
              defaultValue={props.poll.name}
            />
            {optionFields}
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button
                variant="contained"
                color="primary"
                sx={{ borderRadius: "0", boxShadow: "none", mr: "auto" }}
                onClick={addOptionField}
              >
                +
              </Button>
              <Button
                variant="contained"
                sx={{ borderRadius: "0", boxShadow: "none", mr: 2 }}
                onClick={() => props.hideEdit()}
              >
                Закрыть
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{ borderRadius: "0", boxShadow: "none" }}
                onClick={handleSubmit}
              >
                Сохранить
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
