type SuccesModalProps = {
  open: boolean;
  onHide: () => void;
  successMessage: string;
};

export const SuccessModal = (props: SuccesModalProps) => {
  const handleClose = () => {
    props.onHide();
  };

  return (
    <div className={`modal modal--success ${props.open && "modal--active"}`}>
      <div className="modal__backdrop" onClick={handleClose}></div>
      <form className="modal__form">
        <div className="modal__close" onClick={handleClose}>
          <svg>
            <use xlinkHref="#close" />
          </svg>
        </div>
        <h4 className="modal__title">Успешно!</h4>
        <div className="modal__text">{props.successMessage}</div>
        <button
          className="modal__btn btn"
          onClick={(e) => {
            e.preventDefault();
            handleClose();
          }}
        >
          Закрыть
        </button>
      </form>
    </div>
  );
};
