import { Dispatch, SetStateAction, createContext } from "react";
import { TarrifLevel } from "../models/TarrifLevel";

export type LoginRequest = {
  mail: string;
  password: string;
};

type AuthContextType = {
  isAuthenticated: boolean;
  setAuth: (auth: boolean) => void;
  loginModal: boolean;
  setLoginModal: Dispatch<SetStateAction<boolean>>;
  signUpModal: boolean;
  setSignUpModal: Dispatch<SetStateAction<boolean>>;
  forgotPasswordModal: boolean;
  setForgotPasswordModal: Dispatch<SetStateAction<boolean>>;
  successModal: boolean;
  setSuccessModal: Dispatch<SetStateAction<boolean>>;
  successMessage: string;
  setSuccessMessage: Dispatch<SetStateAction<string>>;
  tarrif: TarrifLevel;
  setTarrif: Dispatch<SetStateAction<TarrifLevel>>;
};

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  setAuth: () => {},
  loginModal: false,
  setLoginModal: () => {},
  signUpModal: false,
  setSignUpModal: () => {},
  forgotPasswordModal: false,
  setForgotPasswordModal: () => {},
  successModal: false,
  setSuccessModal: () => {},
  successMessage: "",
  setSuccessMessage: () => {},
  tarrif: TarrifLevel.Base,
  setTarrif: () => {},
});
