import { ModalProps } from "../../models/ModalProps";

export const LimitErrorModal = (props: ModalProps) => {
  const handleClose = () => {
    props.onHide();
  };

  return (
    <div className={`modal modal--success ${props.open && "modal--active"}`}>
      <div className="modal__backdrop" onClick={handleClose}></div>
      <form className="modal__form">
        <div className="modal__close" onClick={handleClose}>
          <svg>
            <use xlinkHref="#close" />
          </svg>
        </div>
        <h4 className="modal__title">Достигнут лимит</h4>
        <div className="modal__text">
          Превышено количество доступных голосований.
        </div>
        <span className="modal__notice" style={{ textAlign: "center" }}>
          Для неограниченного количества голосований подключите тариф{" "}
          <a href="/tarrifs">Продвинутый</a> или{" "}
          <a href="/tarrifs">Профессиональный</a>
        </span>
        <button
          className="modal__btn btn"
          onClick={(e) => {
            e.preventDefault();
            handleClose();
          }}
        >
          Закрыть
        </button>
      </form>
    </div>
  );
};
