import jwt from "jwt-decode";
import { TarrifLevel } from "../models/TarrifLevel";

type TokenPayload = {
  userId: number;
  iat: number;
  tarrif: string;
};

const ACCESS_TOKEN = "access_token";

class TokenStorage {
  public setToken = (token: string) => {
    localStorage.setItem(ACCESS_TOKEN, token);
  };

  public getToken = () => {
    return localStorage.getItem(ACCESS_TOKEN)
      ? localStorage.getItem(ACCESS_TOKEN)
      : "";
  };

  public removeToken = () => {
    localStorage.removeItem(ACCESS_TOKEN);
  };

  public getTokenPayload = (): TokenPayload | false => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
      const tokenObj: TokenPayload = jwt(token);
      return tokenObj;
    } else {
      return false;
    }
  };

  public isUserSet = () => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
      const tokenObj: TokenPayload = jwt(token);
      return tokenObj.userId ? true : false;
    } else {
      return false;
    }
  };

  public getUserId = (): number | false => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
      const tokenObj: TokenPayload = jwt(token);
      return tokenObj.userId;
    } else {
      return false;
    }
  };

  public getUserTarrif = (): TarrifLevel => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
      const tokenObj: TokenPayload = jwt(token);
      return tokenObj.tarrif as TarrifLevel;
    } else {
      return TarrifLevel.Base;
    }
  };


}

export const tokenStorage = new TokenStorage();
