import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { serverApi } from "../../api/ServerApi";

export const PersonalEntry = () => {
  const navigate = useNavigate();
  const [findError, setFindError] = useState<boolean>(false);
  const [findValue, setFindValue] = useState<string>("");

  async function findSpot(spotId: string) {
    const isNumber = !isNaN(parseInt(spotId));
    if (isNumber) {
      const response = await serverApi.findSpot(spotId);
      if (response) {
        const id = response.id;
        navigate(`/spot/${id}`, { replace: true });
      } else {
        setFindError(true);
      }
    } else {
      setFindError(true);
    }
  }

  return (
    <section className="personal-entry">
      <form action="#" className="personal-entry__form">
        <input
          type="text"
          className="personal-entry__input"
          placeholder="Введите ID встречи"
          value={findValue}
          onChange={(e) => {
            setFindError(false);
            setFindValue(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              findSpot(findValue);
            }
          }}
        />
        {findError && (
          <span className="promo-entry__input-error">Встреча не найдена</span>
        )}
        <button
          className="personal-entry__submit btn btn--success"
          onClick={(e) => {
            e.preventDefault();
            findSpot(findValue);
          }}
        >
          Присоединиться
        </button>
      </form>
    </section>
  );
};
