import { useNavigate } from "react-router-dom";
import { ModalProps } from "../../models/ModalProps";
import { useState } from "react";
import { serverApi } from "../../api/ServerApi";

export const EnterModal = (props: ModalProps) => {
  const handleClose = () => {
    props.onHide();
  };

  const navigate = useNavigate();
  const [findError, setFindError] = useState<boolean>(false);
  const [findValue, setFindValue] = useState<string>("");

  async function findSpot(spotId: string) {
    const isNumber = !isNaN(parseInt(spotId));
    if (isNumber) {
      const response = await serverApi.findSpot(spotId);
      if (response) {
        const id = response.id;
        navigate(`/spot/${id}`, { replace: true });
      } else {
        setFindError(true);
      }
    } else {
      setFindError(true);
    }
  }

  return (
    <div className={`modal modal--create ${props.open && "modal--active"}`}>
      <div className="modal__backdrop" onClick={handleClose}></div>
      <form action="#" className="modal__form">
        <div className="modal__close" onClick={handleClose}>
          <svg>
            <use xlinkHref="#close" />
          </svg>
        </div>
        <h4 className="modal__title">
          Присоединиться <br /> к встрече
        </h4>
        <label className="modal__label">
          <input
            type="text"
            className="modal__input"
            placeholder="Название встречи"
            value={findValue}
            onChange={(e) => setFindValue(e.target.value)}
          />
          <span
            className={`modal__tooltip ${
              findError && "modal__tooltip--active"
            }`}
          >
            Встреча не найдена
          </span>
        </label>
        <button
          className="modal__btn btn btn--success"
          onClick={(e) => {
            e.preventDefault();
            findSpot(findValue);
          }}
        >
          Присоединиться
        </button>
      </form>
    </div>
  );
};
