import { useContext, useState } from "react";
import { EnterModal } from "../modals/EnterModal";
import { AuthContext } from "../../context/AuthContext";

export const PromoFeatures = () => {
  const { setLoginModal } = useContext(AuthContext);
  const [enterModal, setEnterModal] = useState<boolean>(false);

  return (
    <>
      <EnterModal open={enterModal} onHide={() => setEnterModal(false)} />
      <section className="promo-features">
        <div className="promo-feature">
          <div className="promo-feature__tag">Возможности</div>
          <h3 className="promo-feature__title">Для организаторов:</h3>
          <ul className="promo-feature__list">
            <li>создавать опросы;</li>
            <li>модерировать вопросы;</li>
            <li>вовлекать аудиторию в общение;</li>
            <li>получать и анализировать обратную связь на мероприятиях.</li>
          </ul>
          <button
            className="promo-feature__btn"
            onClick={() => setLoginModal(true)}
          >
            <span>Попробовать бесплатно</span>
            <svg>
              <use xlinkHref="#arrow-right"></use>
            </svg>
          </button>
        </div>

        <div className="promo-feature">
          <div className="promo-feature__tag">Возможности</div>
          <h3 className="promo-feature__title">Для участников:</h3>
          <ul className="promo-feature__list">
            <li>подключение без регистрации;</li>
            <li>задавать неограниченное количество вопросов;</li>
            <li>участвовать в голосованиях и обсуждениях;</li>
            <li>выбирать режим анонимности;</li>
            <li>влиять на выбор лучших вопросов.</li>
          </ul>
          <button
            className="promo-feature__btn"
            onClick={() => setEnterModal(true)}
          >
            <span>Подключиться к встрече</span>
            <svg>
              <use xlinkHref="#arrow-right"></use>
            </svg>
          </button>
        </div>
      </section>
    </>
  );
};
