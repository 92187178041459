import {
  Container,
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
  createTheme,
} from "@mui/material";

export function Layout({ children }: { children: JSX.Element }) {
  const customTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#F5F4F5",
        contrastText: "#214875",
      },
      secondary: {
        main: "#214875",
        contrastText: "#FFF",
      },
      success: {
        main: "#4DB652",
        contrastText: "#FFF",
      },
      info: {
        main: "#96A0AC",
      },
    },
    typography: {
      body1: {
        color: "#172331",
      },
    },
  });

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vmin",
  };
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: customTheme.palette.primary.main,
            "& .MuiInputBase-root": {
              borderRadius: "0",
              border: "0px solid #D2CED2",
              color: "#515E6F",
              backgroundColor: "#fff",
            },
            "& .MuiInputBase-root.Mui-focused": {
              borderRadius: "0",
              border: "0px solid #D2CED2",
            },
            "& .MuiFormLabel-root": {
              color: "#515E6F",
            },
            "& .MuiFormLabel-root.Mui-focused": {
              color: "#515E6F",
              background: "#F5F4F5",
            },
          },
        }}
      />
      <Container maxWidth="lg" sx={containerStyle}>
        {children}
      </Container>
    </ThemeProvider>
  );
}
