function numWord(value: number, words: string[]) {
  value = Math.abs(value) % 100;
  var num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num == 1) return words[0];
  return words[2];
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

function formatDate(dateSting: string): string {
  const date = new Date(dateSting);
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join(".");
}

export default function getTimeAgo(time: any) {
  switch (typeof time) {
    case "number":
      break;
    case "string":
      time = +new Date(time);
      break;
    case "object":
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }

  const seconds = (+new Date() - time) / 1000;

  if (seconds < 60) {
    const word = numWord(seconds, ["секунда", "секунды", "секунд"]);
    return seconds + " " + word + " назад";
  }

  const minutes = Math.floor(seconds / 60);

  if (minutes < 60) {
    const word = numWord(minutes, ["минута", "минуты", "минут"]);
    return minutes + " " + word + " назад";
  }

  const hours = Math.floor(minutes / 60);

  if (hours < 24) {
    const word = numWord(hours, ["час", "часа", "часов"]);
    return hours + " " + word + " назад";
  }

  return formatDate(time);
}
