import PromoBannerImg from "../../assets/images/content/promo-image.png";

export const PromoBanner = () => {
  return (
    <section className="promo-banner">
      <div className="promo-banner__image">
        <img src={PromoBannerImg} alt="Вдиалоге UI/UX" />
      </div>
    </section>
  );
};
