import { Grid, Box, Card, Typography } from "@mui/material";
import { ParticipantAnswerParams, QuizQuestion } from "./Quiz";
import { enviroment } from "../../Enviroment";

interface QuizQuestionParticipantItemProps {
  question: QuizQuestion;
  handleAnswer: (params: ParticipantAnswerParams) => void;
  participantId: string | null;
}

export function QuizQuestionParticipantItem(
  props: QuizQuestionParticipantItemProps
) {
  const url = enviroment.getHttpEndpoint();

  return (
    <Card
      sx={{
        minWidth: 275,
        position: "relative",
        borderRadius: "0",
        boxShadow: "none",
        my: 2,
        "& .MuiInputBase-root": {
          borderRadius: "0",
          border: "0px solid #D2CED2",
          color: "#515E6F",
        },
        "& .MuiInputBase-root.Mui-focused": {
          borderRadius: "0",
          border: "0px solid #D2CED2",
        },
        "& .MuiFormLabel-root": {
          color: "#515E6F",
        },
        "& .MuiFormLabel-root.Mui-focused": {
          color: "#515E6F",
          background: "#F5F4F5",
        },
      }}
    >
      <Box sx={{ padding: 2 }}>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="space-between"
          sx={{ p: 1 }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Typography fontSize={24}>
              {"Вопрос: " + props.question.question}
            </Typography>

            {props.question.image_name &&
              props.question.image_name !== "null" && (
                <img
                  src={`${url}/uploads/${props.question.image_name}`}
                  style={{
                    width: "400px",
                    maxWidth: "100%",
                  }}
                />
              )}

            {props.question.answers.map((answer) => {
              return (
                <Typography
                  fontSize={18}
                  key={`question_answer_${answer.id}`}
                  p={2}
                  border={1}
                  my={2}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      background: "#D2CED2",
                    },
                  }}
                  onClick={() => {
                    props.handleAnswer({
                      question: props.question,
                      answerId: answer.id,
                      participantId: props.participantId,
                    });
                  }}
                >
                  {"Ответ: " + answer.text}
                </Typography>
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}
