import { useState } from "react";
import { OrderModal } from "./modals/OrderModal";

export default function Footer() {
  const [orderModal, setOrderModal] = useState<boolean>(false);

  return (
    <footer className="footer">
      <OrderModal open={orderModal} onHide={() => setOrderModal(false)} />
      <div className="footer__container container">
        <div className="footer__contacts">
          <a href="tel:+73952261260" className="footer__contacts-link">
            +7 3952 261-260
          </a>
          <a href="mailto:sales@t-code.ru" className="footer__contacts-link">
            sales@t-code.ru
          </a>
        </div>

        <div className="footer__logo">
          <a href="/" style={{ margin: 0 }}>
            <svg>
              <use xlinkHref="#logo_light"></use>
            </svg>
          </a>
          <a href="/privacy" className="footer__policy">
            Политика конфиденциальности
          </a>
        </div>

        <div className="footer__info">
          <button
            className="footer__info-btn"
            onClick={() => setOrderModal(true)}
          >
            Консультация по продукту
          </button>
          <span className="footer__info-text" style={{ textAlign: "center" }}>
            Разработано в{" "}
            <a
              href="https://t-code.ru"
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              true.code
            </a>
            <br />
            ИП Шеленговский А.В.
            <br />
            ИНН 381298935704
          </span>
        </div>
      </div>
    </footer>
  );
}
