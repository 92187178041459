import { Box, CircularProgress } from "@mui/material";
import QRCode from "qrcode";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function SpotQr() {
  const { spotId } = useParams();
  const [qr, setQr] = useState<string>("");
  const [pending, setPending] = useState<boolean>(true);

  const generateQRCode = () => {
    const url = window.location.origin + "/spot/" + spotId;
    QRCode.toDataURL(
      url,
      {
        width: 800,
        margin: 2,
      },
      (err, url) => {
        if (err) return console.error(err);
        setQr(url);
        setPending(false);
      }
    );
  };

  useEffect(() => {
    generateQRCode();
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {pending ? (
        <CircularProgress />
      ) : (
        <Box
          component="img"
          sx={{
            height: 500,
            width: 500,
            maxHeight: { xs: 500, md: 500 },
            maxWidth: { xs: 500, md: 500 },
          }}
          alt="QR-код"
          src={qr}
        />
      )}
    </div>
  );
}

export default SpotQr;
