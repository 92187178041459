import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { isModerator } from "../../utils/isModerator";
import { authorStorage } from "../../utils/authorStorage";

type SpotFormProps = {
  moderation: boolean;
  connection: WebSocket;
  moderatorId: string;
};

export default function SpotForm(props: SpotFormProps) {
  const spotId: string = window.location.pathname.split("/")[2];

  const [newQuestion, setNewQuestion] = useState<string>("");
  const [author, setAuthor] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const addQuestion = () => {
    if (newQuestion === "") {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    } else {
      const request = {
        event: "addQuestion",
        spotId: spotId,
        text: newQuestion,
        author: author
          ? author
          : isModerator(props.moderatorId)
          ? "Модератор"
          : "Аноним",
        status:
          props.moderation && isModerator(spotId) ? "approved" : "awaiting",
        author_id: authorStorage.getAuthorId(),
        datetime: new Date(),
      };

      if (props.connection) {
        props.connection.send(JSON.stringify(request));
      }
    }
  };

  useEffect(() => {
    authorStorage.setAuthorId();
  }, []);

  return (
    <Box
      sx={{
        minHeight: "100%",
      }}
    >
      <form onSubmit={(event) => event.preventDefault()}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
            position: "relative",
            mt: 3,
            "& .MuiInputBase-root": {
              borderRadius: "0",
              border: "0px solid #D2CED2",
              color: "#515E6F",
            },
            "& .MuiInputBase-root.Mui-focused": {
              borderRadius: "0",
              border: "0px solid #D2CED2",
            },
            "& .MuiFormLabel-root": {
              color: "#515E6F",
            },
            "& .MuiFormLabel-root.Mui-focused": {
              color: "#515E6F",
              background: "#F5F4F5",
            },
          }}
        >
          <TextField
            sx={{
              background: "#fff",
              mb: 2,
            }}
            value={author}
            label="Ваше имя (необязательно)"
            onChange={(e) => setAuthor(e.target.value)}
          />
          <TextField
            fullWidth
            name="message"
            value={newQuestion}
            required
            multiline
            label="Ваш вопрос"
            rows={4}
            onChange={(e) => setNewQuestion(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                addQuestion();
                setNewQuestion("");
                (e.target as HTMLFormElement).blur();
              }
            }}
            sx={{
              background: "#fff",
              height: "100%",
            }}
          />
          {error && (
            <Typography
              sx={{
                position: "absolute",
                bottom: -20,
                right: 10,
                fontSize: "12px",
                color: "red",
              }}
            >
              Вопрос не может быть пустым
            </Typography>
          )}
          <Button
            color="success"
            size="large"
            variant="contained"
            sx={{
              position: "absolute",
              bottom: 10,
              right: 10,
              borderRadius: "0px",
              boxShadow: "none",
              "& .MuiButtonBase-root:hover": {
                boxShadow: "none",
              },
              maxWidth: "295px",
              padding: { xs: "6px", md: "8px 22px" },
              fontSize: { xs: "14px", md: "1rem" },
              width: { xs: "auto", md: "25vmax" },
              whiteSpace: "nowrap",
            }}
            onClick={() => {
              addQuestion();
              setNewQuestion("");
            }}
          >
            Задать вопрос
          </Button>
        </Box>
      </form>
    </Box>
  );
}
