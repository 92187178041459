import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { QuizItem } from "../components/quiz/Quiz";
import { serverApi } from "../api/ServerApi";
import { useParams } from "react-router-dom";
import { Layout } from "../components/Layout";

function QuizResultsPage() {
  const { spotId } = useParams();
  const [pending, setPending] = useState<boolean>(true);
  const [quiz, setQuiz] = useState<QuizItem | null>(null);
  const [updateInterval, setUpdateInterval] = useState<number>(2000);

  const updatingInterval = useRef<any | null>(null);

  const startUpdating = () => {
    updatingInterval.current = setInterval(() => {
      getQuiz();
      if (isQuizFinished()) {
        setUpdateInterval(5000);
      } else {
        setUpdateInterval(2000);
      }
    }, updateInterval);
    setPending(false);
  };

  const getQuiz = async () => {
    if (spotId !== undefined) {
      const response = await serverApi.getQuiz(spotId);
      const quizItem: QuizItem = {
        ...response.quiz,
        questions: response.questions,
        participants: response.participants,
      };
      setQuiz(quizItem);
    }
  };

  function getQuizFinishTime() {
    if (quiz && quiz.start_time) {
      const finishTime = new Date(quiz.start_time);
      quiz.questions.forEach((question) => {
        finishTime.setSeconds(
          finishTime.getSeconds() + question.sec_for_answer
        );
      });
      // todo: просто компенсация на задержку
      finishTime.setSeconds(finishTime.getSeconds() + 2);
      return finishTime;
    } else {
      return new Date();
    }
  }

  function isQuizFinished() {
    if (quiz && quiz.start_time) {
      const now = new Date();
      const finishTime = getQuizFinishTime();

      return now.getTime() > finishTime.getTime();
    } else {
      return false;
    }
  }

  useEffect(() => {
    startUpdating();

    return () => {
      clearInterval(updatingInterval.current);
      updatingInterval.current = null;
    };
  }, []);

  if (quiz === null || pending) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!quiz.started) {
    return (
      <Layout>
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {pending ? (
            <CircularProgress />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h2>Квиз еще не начат</h2>
            </Box>
          )}
        </div>
      </Layout>
    );
  }

  const participants = quiz.participants.sort((a, b) => b.points - a.points);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "scroll",
          scrollbarWidth: "none",
        }}
        className="hide-scroll-bar"
      >
        <Typography variant="h6">Результаты квиза "{quiz.name}"</Typography>
        <TableContainer
          component={Paper}
          sx={{ my: 2, overflow: "scroll" }}
          className="hide-scroll-bar"
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Имя</TableCell>
                <TableCell align="center">Очков</TableCell>
                <TableCell align="center">Всего ответов</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {participants.map((participant, index) => (
                <TableRow
                  key={"participant_id_" + participant.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Box>{participant.name}</Box>
                  </TableCell>
                  <TableCell align="center">{participant.points}</TableCell>
                  <TableCell align="center">
                    {participant.question_answered}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Layout>
  );
}

export default QuizResultsPage;
