import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUpAlt";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import getTimeAgo from "../../utils/getTimeAgo";
import { isModerator } from "../../utils/isModerator";
import { useEffect, useState } from "react";
import { Question } from "./SpotList";
import { authorStorage } from "../../utils/authorStorage";
import { serverApi } from "../../api/ServerApi";

export interface SpotListItemProps {
  moderation: boolean;
  moderatorId: string;
  question: Question;
  connection: WebSocket;
}

export default function SpotListItem(props: SpotListItemProps) {
  const [liked, setLiked] = useState(isLikedInStorage(props.question.id));
  const [isLikeLoading, setIsLikeLoading] = useState(false);
  const [disableLikeBtn, setDisableLikeBtn] = useState(false);

  const [deletePopover, setDeletePopover] = useState<boolean>(false);

  useEffect(() => {
    setLiked(isLikedInStorage(props.question.id));
  }, [props]);

  const deleteQuestion = () => {
    const request = {
      id: props.question.id,
      event: "deleteQuestion",
    };
    if (props.connection) {
      props.connection.send(JSON.stringify(request));
    }
    setDeletePopover(false);
  };

  const theme = useTheme();

  function isLikedInStorage(id: any) {
    const likesInStorage = localStorage.getItem("likes");
    return likesInStorage?.split(",").includes(String(id));
  }

  const setLikeToStorage = (id: any) => {
    const likesInStorage = localStorage.getItem("likes");
    if (likesInStorage === null) {
      localStorage.setItem("likes", id);
    } else {
      const updatedLikes = likesInStorage.split(",");
      updatedLikes.push(id);
      localStorage.setItem("likes", updatedLikes.join(","));
    }
  };

  const removeLikeFromStorage = (id: number) => {
    const likesInStorage = localStorage.getItem("likes");
    const updatedLikes = likesInStorage
      ?.split(",")
      .filter((i) => i !== id.toString())
      .join(",");
    localStorage.setItem("likes", String(updatedLikes));
  };

  const handleLike = () => {
    setDisableLikeBtn(true);
    setTimeout(() => {
      setDisableLikeBtn(false);
    }, 1500);
    setIsLikeLoading(true);
    if (liked) {
      removeLikeFromStorage(props.question.id);
      serverApi
        .changeLikes(props.question.id, props.question.likes - 1)
        .then(() => {
          setLiked(false);
          props.connection.send(
            JSON.stringify({
              event: "likeQuestion",
              id: props.question.id,
            })
          );
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIsLikeLoading(false);
        });
    } else {
      serverApi
        .changeLikes(props.question.id, props.question.likes + 1)
        .then(() => {
          setLiked(true);
          props.connection.send(
            JSON.stringify({
              event: "likeQuestion",
              id: props.question.id,
            })
          );
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIsLikeLoading(false);
        });
      setLikeToStorage(props.question.id);
    }
  };

  return (
    <Card
      sx={{
        minWidth: 275,
        border:
          props.moderation && props.question.status !== "approved"
            ? "1px dashed #818181"
            : "1px solid #D2CED2",
        opacity: props.question.status === "declined" ? "0.5" : "1",
        position: "relative",
        borderRadius: "0px",
        boxShadow: "none",
      }}
    >
      <CardContent>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="space-between"
        >
          <Grid item xs={8}>
            <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <Avatar>{props.question.author[0].toUpperCase()}</Avatar>
              <Box>
                <Typography variant="h6" lineHeight={1.1}>
                  {props.question.author}
                </Typography>

                <Typography color="text.secondary" variant="body1">
                  {getTimeAgo(props.question.date)}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Button
              variant="contained"
              sx={{
                background: theme.palette.primary.main,
                color: theme.palette.secondary.contrastText,
                cursor: "pointer",
                borderRadius: "0px",
                width: { xs: "10vmax", md: "15vmax" },
                maxWidth: "205px",
                boxShadow: "none",
              }}
              onClick={handleLike}
              disabled={disableLikeBtn}
            >
              <ThumbUpIcon color={liked ? "secondary" : "disabled"} />
              <Typography sx={{ mt: 0, ml: 0.7 }} color="text.secondary">
                {props.question.likes}
              </Typography>
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="div"
              sx={{ wordWrap: "anywhere", mb: 4 }}
            >
              {props.question.text}
            </Typography>
          </Grid>
        </Grid>

        {props.question.author_id === authorStorage.getAuthorId() && (
          <Box
            sx={{
              textAlign: "right",
              position: "absolute",
              bottom: "6px",
              right: "6px",
            }}
          >
            <IconButton
              sx={{ ml: "auto" }}
              aria-label="question"
              onClick={() => setDeletePopover(true)}
              id={`question-delete-${props.question.id}`}
            >
              <DeleteOutlineOutlinedIcon color="info" />
            </IconButton>
            <Popover
              open={deletePopover}
              onClose={() => setDeletePopover(false)}
              anchorEl={document.querySelector(
                `#question-delete-${props.question.id}`
              )}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <List sx={{ p: 0 }}>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => deleteQuestion()}>
                    <ListItemText primary="Удалить вопрос" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Popover>
          </Box>
        )}

        {props.moderation ? (
          <Box
            style={{
              color:
                props.question.status === "approved"
                  ? "#4DB652"
                  : props.question.status === "declined"
                  ? "#f44336"
                  : "#214875",
              position: "absolute",
              bottom: "10px",
              left: "20px",
            }}
          >
            {props.question.status === "awaiting" && "На модерации"}
            {props.question.status === "approved" && "Подтвержден"}
            {props.question.status === "declined" && "Отклонен"}
          </Box>
        ) : (
          ""
        )}

        {props.moderation && isModerator(props.moderatorId) ? (
          <CardActions sx={{ mb: 2 }}>
            {props.question.status !== "awaiting" ? (
              <Button
                variant="outlined"
                color="inherit"
                sx={{ mr: "auto", borderRadius: "0px" }}
                onClick={() => {
                  const request = {
                    event: "setStatusQuestion",
                    status: "awaiting",
                    id: props.question.id,
                  };
                  if (props.connection) {
                    props.connection.send(JSON.stringify(request));
                  }
                }}
              >
                Вернуть
              </Button>
            ) : (
              <Box sx={{ display: "flex", ml: "auto", gap: "20px" }}>
                <Button
                  variant="outlined"
                  color="success"
                  sx={{ mr: "auto", borderRadius: "0px" }}
                  onClick={() => {
                    const request = {
                      event: "setStatusQuestion",
                      status: "approved",
                      id: props.question.id,
                    };
                    if (props.connection) {
                      props.connection.send(JSON.stringify(request));
                    }
                  }}
                >
                  Утвердить
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  sx={{ mr: "auto", borderRadius: "0px" }}
                  onClick={() => {
                    const request = {
                      event: "setStatusQuestion",
                      status: "declined",
                      id: props.question.id,
                    };
                    if (props.connection) {
                      props.connection.send(JSON.stringify(request));
                    }
                  }}
                >
                  Отклонить
                </Button>
              </Box>
            )}
          </CardActions>
        ) : (
          ""
        )}
      </CardContent>
    </Card>
  );
}
