import { useState } from "react";
import { AuthContext } from "./AuthContext";
import { LoginModal } from "../components/modals/LoginModal";
import { SignUpModal } from "../components/modals/SignUpModal";
import { ForgotPasswordModal } from "../components/modals/ForgotPasswordModal";
import { SuccessModal } from "../components/modals/SuccessModal";
import { TarrifLevel } from "../models/TarrifLevel";
import { tokenStorage } from "../utils/tokenStorage";

export const AuthContextProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [isAuthenticated, setAuth] = useState<boolean>(
    tokenStorage.isUserSet()
  );

  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [signUpModal, setSignUpModal] = useState<boolean>(false);
  const [forgotPasswordModal, setForgotPasswordModal] =
    useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");

  // console.log(tokenStorage.getTokenPayload())
  const [tarrif, setTarrif] = useState<TarrifLevel>(tokenStorage.getUserTarrif());
    
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        setAuth: setAuth,
        loginModal: loginModal,
        setLoginModal: setLoginModal,
        signUpModal: signUpModal,
        setSignUpModal: setSignUpModal,
        forgotPasswordModal: forgotPasswordModal,
        setForgotPasswordModal: setForgotPasswordModal,
        successModal: successModal,
        setSuccessModal: setSuccessModal,
        successMessage: successMessage,
        setSuccessMessage: setSuccessMessage,
        tarrif: tarrif,
        setTarrif: setTarrif,
      }}
    >
      {children}

      <LoginModal open={loginModal} onHide={() => setLoginModal(false)} />
      <SignUpModal open={signUpModal} onHide={() => setSignUpModal(false)} />
      <ForgotPasswordModal
        open={forgotPasswordModal}
        onHide={() => setForgotPasswordModal(false)}
      />
      <SuccessModal
        open={successModal}
        onHide={() => setSuccessModal(false)}
        successMessage={successMessage}
      />
    </AuthContext.Provider>
  );
};
