import { Box, IconButton, LinearProgress, Link, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useContext, useEffect, useRef, useState } from "react";
import SpotTitle from "../components/spot/SpotTitle";
import SpotForm from "../components/spot/SpotForm";
import SpotList from "../components/spot/SpotList";
import { useLocation } from "react-router";
import { SpotTabs } from "../components/spot/SpotTabs";
import { PollsList } from "../components/polls/PollsList";
import { Layout } from "../components/Layout";
import { isModerator } from "../utils/isModerator";
import { enviroment } from "../Enviroment";
import { tokenStorage } from "../utils/tokenStorage";
import { Quiz, QuizItem } from "../components/quiz/Quiz";
import { useNavigate } from "react-router-dom";
import { voteStorage } from "../utils/voteStorage";
import { AuthContext } from "../context/AuthContext";
import { TarrifLevel } from "../models/TarrifLevel";

export enum SpotTabType {
  QA,
  Polls,
  Quiz,
}

export type PollOption = {
  id: number;
  option_name: string;
  poll_id: number;
  spot_id: number;
  votes: number;
};

export type Poll = {
  id: number;
  name: string;
  published: number;
  spot_id: number;
};

let connectingCounter = 0;
const MaxConnectionTrying = 30;

const Spot = () => {
  const location = useLocation();
  const spotId: string = location.pathname.split("/")[2];

  const { setTarrif } = useContext(AuthContext);

  const socket = useRef<any>(null);
  const WS_URL: string = enviroment.getWsEndpoint();

  const [connected, setConnected] = useState<boolean>(false);
  const [waitingToReconnect, setWaitingToReconnect] = useState<boolean>(false);
  const [notAvailable, setNotAvailable] = useState<boolean>(false);

  const [spotTitle, setSpotTitle] = useState<string>("");
  const [questions, setQuestions] = useState<any>([]);
  const [polls, setPolls] = useState<Poll[]>([]);
  const [pollsOptions, setPollsOptions] = useState<PollOption[]>([]);
  const [quiz, setQuiz] = useState<QuizItem | null>(null);
  const [moderation, setModeration] = useState<boolean>(true);
  const [moderatorId, setModeratorId] = useState<string>("");
  const [sort, setSort] = useState<any>(0);
  const [spotNotFound, setSpotNotFound] = useState<boolean>(false);
  const [connectLimit, setConnectLimit] = useState<boolean>(false);
  const [withQa, setWithQa] = useState<boolean>(false);
  const [withPolls, setWithPolls] = useState<boolean>(false);
  const [withQuiz, setWithQuiz] = useState<boolean>(false);

  const initialTab = withQa
    ? SpotTabType.QA
    : withPolls
    ? SpotTabType.Polls
    : withQuiz
    ? SpotTabType.Quiz
    : null;
  const [tab, setTab] = useState<SpotTabType | null>(initialTab);

  useEffect(() => {
    setTab(initialTab);
    const lastActiveTab = localStorage.getItem("lastActiveTab");
    if (lastActiveTab) {
      setTab(
        lastActiveTab === "0"
          ? SpotTabType.QA
          : lastActiveTab === "1"
          ? SpotTabType.Polls
          : SpotTabType.Quiz
      );
    }
  }, [withQa, withPolls, withQuiz]);

  const navigate = useNavigate();

  function getSpot() {
    const moderatorId: number | false = tokenStorage.getUserId();
    const request = {
      event: "getSpot",
      spotId: spotId,
      isModerator: moderatorId ? isModerator(String(moderatorId)) : false,
    };
    if (socket.current) {
      socket.current.send(JSON.stringify(request));
    }
  }

  function getQuestions() {
    if (socket.current) {
      socket.current.send(
        JSON.stringify({ event: "getQuestions", spotId: spotId })
      );
    }
  }

  function getPolls() {
    if (socket.current) {
      socket.current.send(
        JSON.stringify({ event: "getPolls", spotId: spotId })
      );
    }
  }

  function getPollsOptions() {
    if (socket.current) {
      socket.current.send(
        JSON.stringify({ event: "getPollsOptions", spotId: spotId })
      );
    }
  }

  function getQuiz() {
    if (socket.current) {
      socket.current.send(JSON.stringify({ event: "getQuiz", spotId: spotId }));
    }
  }

  useEffect(() => {
    if (waitingToReconnect) return;

    if (!socket.current) {
      socket.current = new WebSocket(WS_URL);

      socket.current.onerror = (e: any) => {};

      socket.current.onopen = () => {
        getSpot();
        getQuestions();
        getPolls();
        getQuiz();
        getPollsOptions();
        setConnected(true);
      };

      socket.current.onclose = () => {
        if (waitingToReconnect) return;

        setConnected(false);
        setWaitingToReconnect(true);

        if (connectingCounter < MaxConnectionTrying) {
          setTimeout(() => {
            setWaitingToReconnect(false);
            connectingCounter++;
          }, 1000);
        } else {
          setNotAvailable(true);
        }
      };

      socket.current.onmessage = (event: any) => {
        const response = JSON.parse(event.data);
        if (response.event === "serverError") {
          console.error(response.error);
        }

        if (response.event === "getSpot") {
          if (response.results.length === 0) {
            setSpotNotFound(true);
          } else if (response.results.limit === true) {
            setConnectLimit(true);
          } else {
            const spot = response.results.spot[0];

            setSpotTitle(spot.name ? spot.name : `Встреча #${spotId}`);
            setModeration(spot.moderation);
            setModeratorId(spot.moderator_id);
            setWithQa(!!spot.with_qa);
            setWithPolls(!!spot.with_polls);
            setWithQuiz(!!spot.with_quiz);

            if (
              response.results.updatedToken &&
              isModerator(spot.moderator_id)
            ) {
              tokenStorage.setToken(response.results.updatedToken);
              setTarrif(TarrifLevel.Base);
            }
          }

          return;
        }

        if (response.event === "getQuestions") {
          setQuestions(response.results);
          return;
        }

        if (response.event === "setNewName") {
          setSpotTitle(response.results);
          getSpot();
          return;
        }

        const getQuestionsArray = [
          "addQuestion",
          "deleteQuestion",
          "likeQuestion",
          "setStatusQuestion",
        ];
        if (getQuestionsArray.includes(response.event)) {
          getQuestions();
          return;
        }

        if (response.event === "getPolls") {
          setPolls(response.results);
          return;
        }
        if (response.event === "getQuiz") {
          const quizItem: QuizItem = {
            ...response.results.quiz,
            questions: response.results.questions,
            participants: response.results.participants,
          };
          setQuiz(quizItem);
          return;
        }

        const getQuizArray = [
          "createQuiz",
          "addQuizQuestion",
          "editQuizQuestion",
          "deleteQuizQuestion",
          "changeQuizName",
          "startQuiz",
          "createParticipant",
          "setParticipantAnswer",
        ];
        if (getQuizArray.includes(response.event)) {
          getQuiz();
        }

        if (response.event === "stopQuiz") {
          const isParticipant = localStorage.getItem("quizParticipant");
          if (isParticipant) {
            window.location.reload();
          }
          getQuiz();
          return;
        }

        if (
          response.event === "editQuizQuestion" ||
          response.event === "dropQuizResults"
        ) {
          const isParticipant = localStorage.getItem("quizParticipant");
          if (isParticipant) {
            localStorage.removeItem("quizParticipant");
            window.location.reload();
          }
          return;
        }

        if (response.event === "getPollsOptions") {
          setPollsOptions(response.results);
          return;
        }

        if (response.event === "createPoll") {
          setTimeout(() => {
            getPolls();
            getPollsOptions();
          }, 500);
          return;
        }

        const getPollsArray = ["createPoll", "deletePoll", "vote", "publish"];
        if (getPollsArray.includes(response.event)) {
          getPolls();
          getPollsOptions();
          return;
        }

        if (response.event === "updatePoll") {
          voteStorage.removeVote(response.pollId);
          getPolls();
          getPollsOptions();
          return;
        }
      };

      return () => {
        socket.current.close();
        socket.current = null;
      };
    }
    // eslint-disable-next-line
  }, [waitingToReconnect]);

  const handleTabsChange = (tabType: SpotTabType) => {
    setTab(tabType);
  };

  if (!connected) {
    return (
      <Layout>
        <div className="d-flex flex-column align-items-center">
          {notAvailable ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p color="secondary">Сервер не отвечает.</p>
              <Link href="/" color={"secondary"}>
                На главную
              </Link>
            </Box>
          ) : (
            <>
              <LinearProgress color="secondary" />
              <Box color="secondary" mt={1}>
                Ожидание ответа сервера
              </Box>
            </>
          )}
        </div>
      </Layout>
    );
  }

  if (spotNotFound) {
    return (
      <Layout>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2>Встреча не найдена</h2>
          <Link href="/" color={"secondary"}>
            На главную
          </Link>
        </Box>
      </Layout>
    );
  }

  if (connectLimit) {
    return (
      <Layout>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2>Достигнут лимит участников</h2>
          <h4>Пожалуйста, обратитесь к организатору встречи</h4>
          <Link href="/" color={"secondary"}>
            На главную
          </Link>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <>
        <Box
          component="main"
          sx={{ width: "75%", minWidth: "320px", mb: "15vmin" }}
        >
          <Box sx={{ minHeight: "100vmin", position: "relative" }}>
            <SpotTitle
              spotTitle={spotTitle}
              moderatorId={moderatorId}
              connection={socket.current}
            />

            <SpotTabs
              withQa={withQa}
              withPolls={withPolls}
              withQuiz={withQuiz}
              handleTabsChange={handleTabsChange}
              initialTab={tab}
            />

            {tab === SpotTabType.QA ? (
              <>
                <SpotForm
                  moderation={moderation}
                  connection={socket.current}
                  moderatorId={moderatorId}
                />
                <SpotList
                  questions={questions}
                  moderation={moderation}
                  moderatorId={moderatorId}
                  spotId={spotId}
                  sort={sort}
                  setSort={setSort}
                  connection={socket.current}
                />
              </>
            ) : tab === SpotTabType.Polls ? (
              <PollsList
                moderatorId={moderatorId}
                polls={polls}
                pollsOptions={pollsOptions}
                connection={socket.current}
                spotId={spotId}
              />
            ) : tab === SpotTabType.Quiz ? (
              <Quiz
                moderatorId={moderatorId}
                connection={socket.current}
                spotId={spotId}
                quiz={quiz}
              />
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </>
    </Layout>
  );
};

export default Spot;
